$red_color: rgb(232, 2, 34);

.m {
    &t {
         @for $i from 1 through 150 {
              &--#{$i} {
                   margin-top: #{$i}px;
              }
         }
         &--200 {
              margin-top: 200px;
         }
    }
    &b {
         @for $i from 1 through 150 {
              &--#{$i} {
                   margin-bottom: #{$i}px;
              }
         }
    }
}