.price-list-wrapper {
  display: flex;
  column-gap: 40px;
  overflow-x: scroll;
  padding: 30px 0px 30px 0px;
  white-space: nowrap;
  margin-bottom: 40px;
  height: 560px;
  &::-webkit-scrollbar {
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid #202f75;
    border-radius: 20px;
    background-color: #283583;
  }
  &::-webkit-scrollbar-track {
    background-color: #e5e5e5;
  }
  scrollbar-width: thin;
  scrollbar-color: #202f75 #e5e5e5;
}
//osobny element
.price-container {
  box-shadow: 0px 3px 27px #0000000f;
  min-width: 260px;
  padding: 30px;
  height: 490px;
  &:last-child {
    margin-right: 20px;
  }
  &:first-child {
    margin-left: 20px;
  }
  &__kilometers {
    font-size: 18px;
    color: #707070;
    margin-bottom: 20px;
  }
  &__length-month {
    font-size: 18px;
    margin-bottom: 20px;
    color: #707070;
  }
  &__exceeding-price {
    font-size: 18px;
    color: #707070;
    margin-bottom: 40px;
  }
  &__payment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paragraph {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin: 0;
  }
  &--svg {
    margin-right: 10px;
    margin-bottom: 4px;
  }
  &__default-value {
    cursor: pointer;
    font-size: 14px;
    color: #707070;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}

.price-container--default-price {
  box-shadow: 0px 3px 27px #0000000f;
  min-width: 260px;
  padding: 30px;
  border: 3px solid #293886;
  border-radius: 5px;
  background-color: #f1f2f8;
  &:last-child {
    margin-right: 20px;
  }
  &:first-child {
    margin-left: 20px;
  }
  &__kilometers {
    font-size: 18px;
    color: #707070;
    margin-bottom: 20px;
  }
  &__length-month {
    font-size: 18px;
    margin-bottom: 20px;
    color: #707070;
  }
  &__exceeding-price {
    font-size: 18px;
    color: #707070;
    margin-bottom: 40px;
  }
  &__payment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__paragraph {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin: 0;
  }
  &--svg {
    margin-right: 10px;
    margin-bottom: 4px;
  }
  &__default-value {
    cursor: pointer;
    font-size: 14px;
    color: #707070;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}
