.header-section {
  &__link {
    display: flex;
    text-decoration: none;
    color: #283583;
    grid-gap: 5px;
    gap: 10px;
    align-items: center;
    font-weight: 600;
  }
  &__header {
    color: #283583;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 32px;
  }
}
