.input-files {
  display: grid;
  grid-template-columns: repeat((2, 1fr));
  gap: 15px;
  // margin-top: 30px;
  &__container {
    background-color: #fafafa;
    min-height: 135px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-around;
    &--header {
      text-align: center;
      color: #707070;
    }
  }
}

.input-element {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
  cursor: pointer;
  &__img {
    margin: auto;
    width: 27px;
    height: 35px;
  }
  &__file {
    max-width: 200px;
    cursor: pointer;
    &::-webkit-file-upload-button {
      display: none;
    }
  }
}
