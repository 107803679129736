.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.header {
  display: grid;
  grid-template-columns: auto 24px;
}

.expander {
  color: #515766;
  transition-property: rotate;
  padding: 0;
  margin: 0;

  &--expanded {
    @extend .expander;
    rotate: 180deg;
  }
}

.content-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition-property: grid-template-rows;

  &--expanded {
    @extend .content-wrapper;
    grid-template-rows: 1fr;
  }

  .content {
    overflow: hidden;
  }
}

.expander, .content-wrapper {
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
}
