$blue_color: rgb(40, 53, 131);
$orange_color: #ffad05;

.status-bar {
    display: grid;
    row-gap: 16px;

    &__item {
        display: grid;
        justify-items: center;
        grid-template-rows: 26px 1fr;
        row-gap: 24px;
        padding: 0;
        border: unset;
        background: transparent;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        font-family: 'Source Sans Pro', sans-serif;
        color: $blue_color;
        text-align: center;
        width: 100%;
        min-width: 53px;
        max-width: max-content;
    }
    &__separator {
        width: 100%;
        background-color: $orange_color;
        height: 2px;
        margin-top: 16px;
    }

    &__status-indicator {
        border-radius: 50%;

        &--complete {
            @extend .status-bar__status-indicator;
            background-color: #0aad74;
        }
        &--incomplete {
            @extend .status-bar__status-indicator;
            background-color: #ff0000;
        }
        &--unknown {
            @extend .status-bar__status-indicator;
            background-color: #cecece;
        }
    }
}
