.info-about-payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_price {
    color: #ffad05;
    font-size: 38px;
    font-weight: 900;
    text-align: center;
    display: flex;
    flex-direction: column;

    &-month {
      font-size: 16px;
      color: #000000;
      font-weight: 400;
    }
  }
  &_gross {
    color: #000000;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    margin-top: -10px;
  }
  &_button {
    border: 1px solid #283583;
    border-radius: 30px;
    height: 60px;
    width: 169px;
    background-color: #ffffff;
    color: #283583;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
  &_button--active {
    @extend .info-about-payment-container_button;
    background-color: #283583;
    color: #ffffff;
  }
}
