//ToDo: Część styli do wyrzucenia
%filter-car-container_grey-filter {
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #393939;
    position: absolute;
}
%filter-car-section__strawberry-icon {
    content: '';
    height: 2px;
    background-color: black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.filter-car-container {
    display: grid;
    grid-template-columns: 1fr 600px 155px;
    gap: 24px;
}

.filter-car-section {
    max-width: 400px;
    &__filter-toggle {
        display: flex;
        margin-top: 24px + 25px + 4px; // spacing from heading + height of labels + half margin from price boxes
        align-self: start;
        &__button {
            color: #393939;
            font-size: 16px;
            font-weight: bold;
            outline: none;
            border: none;
            height: 45px;
            background-color: #FAFAFA;
            border-radius: 6px;
            min-width: 135px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            &--active {
                @extend .filter-car-section__filter-toggle__button;
                background-color: #283583;
                color: #fff;
            }
        }

        &__strawberry-icon {
            width: 15px;
            height: 2px;
            background-color: black;
            position: relative;
            &::after {
                @extend %filter-car-section__strawberry-icon;
                width: 10px;
                bottom: -5px;
            }
            &::before {
                @extend %filter-car-section__strawberry-icon;
                width: 20px;
                top: -5px;
            }
            &--active {
                @extend .filter-car-section__filter-toggle__strawberry-icon;
                background-color: #fff;
                &::after {
                    @extend %filter-car-section__strawberry-icon;
                    background-color: #fff;
                    width: 10px;
                    bottom: -5px;
                }
                &::before {
                    @extend %filter-car-section__strawberry-icon;
                    background-color: #fff;
                    width: 20px;
                    top: -5px;
                }
            }
        }
    }
    &__slider {
        margin-top: 24px;
    }
    &__title {
        font-size: 23px;
        font-weight: bold;
        color: #283583;
    }
    &__buttons-container {
        display: flex;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        &__single {
            flex: 50%;
            padding: 11px;
            cursor: pointer;
            min-width: 75px;
            &--orange {
                @extend .filter-car-section__buttons-container__single;
                background-color: #ffad05;
                min-width: 75px;
                color: #fff;
                border-radius: 6px;
            }
        }
    }
    &__filter-button-container {
        display: flex;
        column-gap: 30px;
        grid-column: 1/3;
        position: relative;

        &__single {
            display: flex;
            flex-direction: column;
        }
    }
    &__button-filter-for-mobile {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        z-index: 100;
        position: fixed;
        bottom: 100px;
        left: 50%;
        transform: translate(-50%);
        border: none;
        background-color: #283583;
        border-radius: 31px;
        color: #fff;
        box-shadow: 0px 0px 19px #00000050;
        font-size: 18px;
        font-weight: bold;
        min-width: 169px;
        min-height: 61px;
    }
    &_grey-filter {
        &-container {
            display: flex;
            gap: 12px;
            margin-top: 30px;
            flex-wrap: wrap;
        }
        min-height: 34px;
        border-radius: 20px;
        background-color: #f5f5f5;
        color: #393939;
        align-items: center;
        display: flex;
        justify-content: center;
        width: fit-content;
        padding: 0px 20px;
        font-weight: 500;
        &--clear {
            color: #393939;
            font-weight: 500;
            background-color: transparent;
            border: none;
            font-size: 16px;
        }
        &--close {
            margin-left: 20px;
            height: 20px;
            width: 10px;
            position: relative;
            cursor: pointer;
            &::after {
                @extend %filter-car-container_grey-filter;
                transform: rotate(-45deg);
            }
            &::before {
                @extend %filter-car-container_grey-filter;
                transform: rotate(45deg);
            }
        }
    }
}

@media (max-width: 1200px) {
    .filter-car-container {
        grid-template-columns: none;
    }

    .filter-car-section {
        &_grey-filter-container {
            margin-top: 20px;
        }
        &__filter-toggle {
            margin: 5px 0px;
            &__button {
                min-width: min-content;
                padding: 0px 15px;
            }
        }
        &__filter-toggle--span {
            display: none;
        }
    }

    .filter-car-section__slider {
        min-width: 400px;
    }
    .filter-car-section__filter-button-container {
        animation: none;
        display: grid;
        &__single {
            margin-bottom: 15px;
        }
    }
}
