@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-black-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-black-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-black-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-black-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-black-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-black-webfont.svg#source_sans_problack')
            format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-blackitalic-webfont.svg#source_sans_problack_italic')
            format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-bold-webfont.svg#source_sans_probold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-bolditalic-webfont.svg#source_sans_probold_italic')
            format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-light-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-light-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.svg#source_sans_prolight')
            format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.svg#source_sans_proXLtIt')
            format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-light-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-light-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-light-webfont.svg#source_sans_prolight')
            format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-lightitalic-webfont.svg#source_sans_prolight_italic')
            format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-regular-webfont.svg#source_sans_proregular')
            format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-italic-webfont.svg#source_sans_proitalic')
            format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-semibold-webfont.svg#source_sans_prosemibold')
            format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.eot');
    src: url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.woff2') format('woff2'),
        url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.woff') format('woff'),
        url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.ttf') format('truetype'),
        url('./fonts/sourcesanspro/sourcesanspro-semibolditalic-webfont.svg#source_sans_proSBdIt')
            format('svg');
    font-weight: 600;
    font-style: italic;
}
