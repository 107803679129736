.main-table {
  &__wrapper {
    padding: 0px 100px;
    display: grid;
    justify-content: center;
  }
  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 28px;
  }
  &__header {
    color: #283583;
    font-weight: bold;
    text-align: left;
    padding: 20px 0px !important;
  }
}
