.file__item {
  width: min-content;
  position: relative;
  $item: &;

  &,
  & img {
    border-radius: 8px;
  }

  &--only-one,
  &--only-one img {
    width: 100%;
    height: auto;
  }

  &--overlay {
    img {
      outline: 1px solid #cacaca;
    }

    &:hover img,
    &#{$item}--hover img {
      outline-color: #283583;
      filter: brightness(40%);
    }
  }

  &__delete {
    position: absolute;
    // top: 6px;
    top: 0px;
    // right: 6px;
    right: 6px;
    font-size: 24px;
    font-weight: 900;
    color: $red_color;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }

  &--hover &__delete {
    font-size: 48px;
  }

  &:hover &__delete,
  &--hover &__delete {
    opacity: 1;
    pointer-events: auto;
  }
}
