%button {
  min-width: 181px;
  min-height: 43px;
  background-color: #ffff;
  border: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;

  &:focus {
    outline: none;
  }
}

.filter-groups {
  display: flex;
  margin-bottom: 27px;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 24px;
}

.group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.group__buttons-wrapper {
  display: flex;
}

.group__button {
  @extend %button;
}

.group__button--active {
  @extend %button;
  border-bottom: 2px solid #283583;
}
h3 {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.cars__filters-toggle-button {
  height: 45px;
  align-self: flex-end;
  margin-left: auto;
}

.cars__filters {
  flex-basis: 100%;

  & > div {
    width: 160px;
  }
}
