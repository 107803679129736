.table-container {
  min-width: 1364px;
  width:100%;
  display: grid;
  grid-template-rows: 60px 1fr 70px;
  border-radius: 5px;
  justify-content: left;
  box-sizing: border-box;
}
.table-header{
  min-width: 1364px;
  display: grid;
  background-color: #fcfcfc;
  min-height: 60px;
  padding: 10px 0;
  text-align: center;
  &--inWarehouse {
    @extend .table-header;
    grid-template-columns: 2fr 2fr 3fr 3fr 3fr 2fr;
  }
  &--published {
    @extend .table-header;
    grid-template-columns: 2fr 2fr 3fr 3fr 2fr 3fr 2fr 2fr;
  }
  &--other {
    @extend .table-header;
    grid-template-columns: 2fr 2fr 2fr 4fr 3fr 3fr 2fr;
  }
}

.table-used-cars_single-container {
  border: 1px solid white;
}
.table-used-cars_pagination {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px 25px;
}
.table-used-cars_pagination-single-container {
  display: flex;
}
.table-header-title {
  font-size: 14px;
  font-weight: bold;
  min-height: 60px;
  padding: 10px;
  text-align: center;
}
.car-container {
  display: grid;
  background-color: #ffffff;
  min-height: 60px;
  border-top: 2px solid #f6f6f6;
  border-bottom: 2px solid #f6f6f6;
  &--inWarehouse {
    @extend .car-container;
    grid-template-columns: 2fr 2fr 3fr 3fr 3fr 2fr;
  }
  &--published {
    @extend .car-container;
    grid-template-columns: 2fr 2fr 3fr 3fr 2fr 3fr 2fr 2fr;
  }
  &--other {
    @extend .car-container;
    grid-template-columns: 2fr 2fr 2fr 4fr 3fr 3fr 2fr;
  }
}
.car-container_id {
  display: flex;
  justify-content: center;
  align-items: center;
}
.car-container_hidden {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8A8A8A;
}
.car-container_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.car-container_name-title {
  font-size: 16px;
  max-width: 208px;
}
.car-container_name-number {
  border-radius: 20px;
  background-color: #3b383832;
  min-width: 68px;
  text-align: center;
  min-height: 34px;
  padding: 5px;
}
.car-container_state {
  align-items: center;
  display: flex;
  justify-content: center;
  color: red;
}
.car-container_state--active{
  align-items: center;
  display: flex;
  justify-content: center;
  color: #32B114
  }
.car-container_state--hidden {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #8A8A8A;
}
.car-container_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.table-icon {
  width: 36px;
  }

.number-page-pagination {
  text-align: center;
  min-width: 25px;
  cursor: pointer;
  padding: 17px;
  border: transparent;
  background-color: transparent;
  outline: none;
}
.number-page-pagination:focus,
.number-page-pagination:active,
.number-page-pagination:hover {
  border-bottom: 2px solid #283583;
  font-weight: bold;
  outline: none;
}

.table-used-cars_pagination-single-container {
  display: flex;
  align-items: center;
}
.pagination-arrow {
  height: 12px;
  cursor: pointer;
}

.paggination-arrow-button {
  padding: 17px;
  min-width: 25px;
  border: none;
  background-color: transparent;
  outline: none;
}
.paggination-arrow-button:focus {
  border: none;
  outline: none;
}
.paggination-arrow-button:hover {
  border-bottom: 2px solid #283583;
  font-weight: bold;
  outline: none;
}
.paggination-arrow-button:disabled {
  border: none;
  background-color: transparent;
  outline: none;
  opacity: 0.1;
}
.number-page-pagination--active {
  text-align: center;
  min-width: 25px;
  cursor: pointer;
  padding: 17px;
  border: transparent;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #283583;
  font-weight: bold;
  outline: none;
}
.number-page-pagination--active:active,
.number-page-pagination--active:focus {
  outline: none;
}
