:root {
  //--search-bg-color: #ffffff;
  //ToDo: trzeba rozdzielić n poszczególne własności
  --border-color: #E6E6E6;
  --border-width: 1px;
  --border-style: solid;
  --border: var(--border-width, 1px) var(--border-style, solid) var(--border-color, #E6E6E6);
  --focus-color: #FFAC04;
  --border-radius: 4px;
  --placeholder-color: #A3A3A3;
  --color: black;
}

.root {
  width: 100%;
  display: flex;
  //background-color: var(--search-bg-color, #ffffff);
  border-radius: var(--border-radius, 4px);
  padding-inline: 12px;
  border: var(--border, 1px solid #E6E6E6);
  height: 42px;
  align-items: center;
  gap: 8px;

  label {
    margin-bottom: 0;
  }

  &:focus-within {
    border-color: var(--focus-color, #FFAC04);
  }
}

.search-input {
  width: 100%;
  height: 100%;
  input {
    height: 100%;
    position: relative;
    top: 2px;
    padding: 0;
    color: var(--color, black);

    /* Text XL/Regular */
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    &::placeholder {
      color: var(--placeholder-color, #A3A3A3);
    }

    &:focus {
      caret-color: var(--focus-color, #FFAC04);
    }

    &:hover {
      cursor: pointer;
    }
  }

  button {
    padding: 0;
  }
}

.end-adornment {
  display: flex;
  gap: 12px;
  align-items: center;
}
