:global(.MuiInputBase-input.MuiOutlinedInput-input).input {
  color: #4D4D4D;
  padding: 6.5px 12px;
  border-radius: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 42px;
  box-sizing: border-box;

  & ~ fieldset {
    border-radius: 0;
    border-color: #E6E6E6;
  }
}

:global(.MuiFormLabel-root.MuiInputLabel-root).label {
  color: #393939;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
