.root {
  font-weight: 600;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  line-height: 1rem;
  text-align: center;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

.status-indicator {
  border-radius: 50%;
  width: 22px;
  height: 22px;

  &--complete {
    @extend .status-indicator;
    background-color: #0aad74;
  }
  &--incomplete {
    @extend .status-indicator;
    background-color: #ff0000;
  }
  &--unknown {
    @extend .status-indicator;
    background-color: #cecece;
  }
}
