.table-header {
  grid-template-columns: 2fr 2fr 1fr;
  background-color: #fff;
  padding: 12px 24px;
  min-height: unset;
  :global(.table-header-title) {
    text-align: left;
    padding: 0;
    margin: 0;
    min-height: unset;
  }
}

.table-body {
  display: flex;
  flex-direction: column;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  background-color: #fff;
  padding:  12px 24px;
}

.table-action {
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  .not-allowed {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    background-color: #FAFAFA;
    border-radius: 4px;

    &__edit,
    &__jump-to {
      @extend .icon;
    }

    &__edit img {

      width: 15px;
    }
    &__jump-to img {
      width: 18px;
    }
  }
}

.status {
  &--complete {
    color: #0AAD74;
  }
  &--incomplete {
    color: #ff0000;
  }
}

.search {
  background: white;
  width: 531px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.table-wrapper {
  padding-top: 24px;
}
