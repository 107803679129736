.root {
  /**/
}

.back-button {
  color: black;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
}

.content {
  padding: 24px 0;
  margin: 0 auto;
  width: 1159px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.expandable-container {
  row-gap: 24px;
}

.section-content {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  column-gap: 24px;
  row-gap: 16px;
}

.status {
  position: relative;
  top: 1px; // Takie machlojki bo ta czcionka jest tak jakby bardziej przesunięta w górę co wizualnie pokazuje jakby tekst nie był na środku
  padding-left: 6px;
}
