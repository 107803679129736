.plate-container {
  background-color: white;
  padding: 80px 55px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 32px;

  &__no-margin{
    margin: 0;
    padding-bottom: 0;
  }
  &__title {
    background-color: #fcfcfc;
    min-height: 60px;
    border-radius: 5px 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    padding: 21px;
    &--text {
      font-weight: bold;
      text-align: left;
      font-size: 14px;
    }
  }
}
