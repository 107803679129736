.additional-option{

    &__button{
        border: none;
        border-radius: 4px;
        background-color: #FAFAFA;
    }

    &__popper{
        width: 220px;
        height: 40px;
        padding: 0 8px;
        color: #393939;
        background-color: #FFF;
        box-shadow: 1px 1px 10px 1px rgb(66, 68, 90, 0.2);
        transform: translate(-8px, 8px);
        border-radius: 6px;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        &:hover{
            background-color: #FAFAFA;
        }
    }
}
