.file {
    &__input-wrapper {
        background-color: #fafafa;
        color: #707070;
        border-radius: 13px;
        position: relative;

        input[type='file'] { display: none; }

        svg {
            width: 30px;
            height: 30px;
            margin-bottom: 16px;
        }

        &, & label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            cursor: pointer;
        }

        & > label {
            padding: 24px 48px;
        }
    }

    &__list-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        gap: 12px;

        &--multiple { padding: 12px; }
    }

    &__error {
        color: $red_color;
        font-size: 14px;
        display: inline-block;
    }
}
